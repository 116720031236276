<template>
  <div class="contenido">
    <ImageHeader />
    <div class="is-10 container-data container">
      <div class="columns">
        <div class="is-4 is-offset-4 column has-text-centered">
          <span class="title white bold">{{ $route.params.relatoId }}
            {{ $t("EVALUATOR.STORY.TITLE") }}</span>
        </div>
        <div class="is-3 is-offset-1 column has-text-right">
          <button class="btn-go-to-list btn button" @click="returnList">
            <span>
              <font-awesome-icon class="icono" :icon="['fal', 'list']" />
              <span class="text-l black">{{
                $t("EVALUATOR.STORY.RETURN_LIST")
                }}</span>
            </span>
          </button>
        </div>
      </div>
      <div class="container is-10 data">
        <div class="columns" style="padding-top: 1em;">
          <div class="is-4 is-offset-1 column has-text-left" style="padding-top: 3em;">
            <span class="title-m text-primary btn-slide" @click="previousEval()">
              <font-awesome-icon class="icono" :icon="['fal', 'chevron-left']" />
              {{ $t("EVALUATOR.STORY.BACK") }}
            </span>
          </div>
          <div class="is-1 column has-text-centered">
            <div class="icon-mark liked" :class="{ 'item-liked': evaluacion.nota === 'A', 'disable-like': disableButtonsLike}" @click="evaluarRelato('A')">
              <span class="has-text-centered">
                <font-awesome-icon :icon="['fal', 'heart']" />
              </span>
            </div>
          </div>
          <div class="is-1 column has-text-centered">
            <div class="icon-mark unliked" :class="{ 'item-un-liked': evaluacion.nota === 'B', 'disable-like': disableButtonsLike }"
              @click="evaluarRelato('B')">
              <span class="has-text-centered">
                <font-awesome-icon :icon="['fal', 'times']" />
              </span>
            </div>
          </div>
          <div class="is-4 column has-text-right" style="padding-top: 3em;">
            <span class="title-m text-primary btn-slide" @click="nextEval()">{{ $t("EVALUATOR.STORY.NEXT") }}
              <font-awesome-icon class="icono" :icon="['fal', 'chevron-right']" />
            </span>
          </div>
        </div>
        <div class="separacion">
          <hr />
        </div>
        <div class="columns">
          <div class="is-10 is-offset-1 column" style="padding-top: 1em;" :class="{'is-flex is-justify-content-space-between': evaluacion.necesidades_especiales && evaluacion.necesidades_especiales.length > 0, 'has-text-right': !evaluacion.necesidades_especiales || evaluacion.necesidades_especiales.length === 0 }">
            <span v-if="
                      evaluacion.necesidades_especiales && evaluacion.necesidades_especiales.length > 0
                    "><b-tag type="is-warning" rounded v-for="(necesidad, index) in evaluacion.necesidades_especiales" :key="index">{{ necesidad }}</b-tag></span>
            <span class="text-l bold text-primary download-relato" @click="downloadRelato()">
              <font-awesome-icon class="icono" :icon="['fal', 'print']" />
              {{ $t("EVALUATOR.STORY.PRINT") }}
            </span>
          </div>
        </div>
        <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
          <span class="subtitle bold black">{{
            $t("EVALUATOR.STORY.TITLE_STORY")
            }}</span>
        </div>
        <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
          <p class="text-l background-gray">{{ evaluacion.titulo }}</p>
        </div>
        <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
          <span class="subtitle bold black">{{
            $t("EVALUATOR.STORY.TEXT_STORY")
            }}</span>
        </div>
        <div class="is-10 is-offset-1 column has-text-left" style="padding-top: 1em;">
          <p class="text-l background-gray" v-html="evaluacion.redaccion_resaltada ? evaluacion.redaccion_resaltada : evaluacion.redaccion"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageHeader from "@/components/ImageHeader.vue";

  export default {
    name: "relato",
    components: {
      ImageHeader,
    },
    data() {
      return {
        evaluacion: {},
        evaluaciones: undefined
      };
    },
    created() {
      this.$store.commit("setShowSpinner", true);
      this.getRelato();
    },
    updated() {
      if (
        this.evaluacion.relato_id &&
        this.evaluacion.relato_id !== +this.$route.params.relatoId
      ) {
        this.getRelato();
      }
    },
    computed: {
      disableButtonsLike: function() {
        return this.checkIfButtonsShouldBeDisabled();
      }
    },
    methods: {
      checkIfButtonsShouldBeDisabled() {
        let limit = this.$store.state.evaluadores.evaluacionSlide.limit;

        if (limit !== undefined) {
          if (this.evaluaciones === undefined) {
            this.evaluaciones = this.$store.state.evaluadores.evaluacionSlide.evaluaciones;
          }
          return this.evaluaciones.filter((obj) => obj.nota === 'A').length === limit;
        }

        return false;
      },
      returnList() {
        if(this.$store.state.evaluadores.currentProvince){
          this.$router.push({
            name: (this.$store.state.fases.fasesActuales[0].codigo === 'evaluacion_2' ? "evaluadorf2" : "evaluador"),
            params: { provincia: this.$store.state.evaluadores.currentProvince },
          });
        } else {
          this.$router.push("seleccion-de-provincia");
        }
      },
      getRelato() {
        this.$store
          .dispatch("getEvaluacion", {
            fase: this.$store.state.fases.fasesActuales[0].codigo,
            relato_id: this.$route.params.relatoId,
            usuario_id: this.$route.params.usuarioId,
          })
          .then((evaluacion) => {
            evaluacion.redaccion = evaluacion.redaccion.replace(/\n/g, '<br>');
            if (evaluacion.necesidades_especiales) {
              evaluacion.necesidades_especiales = evaluacion.necesidades_especiales.split(',');
            } else {
              evaluacion.necesidades_especiales = [];
            }
            this.evaluacion = evaluacion;
            this.$store.commit("setShowSpinner", false);
          })
          .catch((error) => {
            this.$store.commit("setShowSpinner", false);
          });
      },
      downloadRelato() {
        this.$store.commit("setShowSpinner", true);
        this.$store.dispatch("downloadRelato", { relato_id: this.evaluacion.relato_id }).then((response) => {
          const file = new Blob([response], {
            type: "application/pdf",
          });
          this.$store.commit("setShowSpinner", false);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = `relato_${this.evaluacion.relato_id}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      },
      evaluarRelato(nota) {
        if(!this.disableButtonsLike){
          if (this.evaluacion.nota !== null) {
            this.$buefy.dialog.confirm({
              title: this.$t("EVALUATOR.MODIFICAR_TITLE"),
              message: this.$t("EVALUATOR.MODIFICAR_TEXT"),
              confirmText: this.$t("EVALUATOR.MODIFICAR_BUTTON"),
              cancelText: this.$t("COMPETITION.CANCELAR"),
              type: "is-danger",
              hasIcon: true,
              iconPack: "far",
              onConfirm: () => {
                this.$store
                  .dispatch("evaluarRelato", {
                    relato_id: this.evaluacion.relato_id,
                    nota,
                    posicion: null,
                    fase: this.$store.state.fases.fasesActuales[0].codigo,
                  })
                  .then((res) => {
                    this.evaluacion.nota = nota;

                    this.evaluaciones.find(e => e.relato_id == this.evaluacion.relato_id).nota = nota;
                  });
              },
            });
          } else {
            this.$store
              .dispatch("evaluarRelato", {
                relato_id: this.evaluacion.relato_id,
                nota,
                posicion: null,
                fase: this.$store.state.fases.fasesActuales[0].codigo,
              })
              .then((res) => {
                this.evaluacion.nota = nota;
                this.evaluaciones.find(e => e.relato_id == this.evaluacion.relato_id).nota = nota;
              });
          }
        }
      },
      previousEval() {
        let index = 0;
        if (this.$store.state.evaluadores.evaluacionSlide.index === 0) {
          index =
            this.$store.state.evaluadores.evaluacionSlide.evaluaciones.length - 1;
        } else {
          index = this.$store.state.evaluadores.evaluacionSlide.index - 1;
        }
        this.goToRelatoDetail(
          this.$store.state.evaluadores.evaluacionSlide.evaluaciones[index],
          index
        );
      },
      nextEval() {
        let index = 0;
        if (
          this.$store.state.evaluadores.evaluacionSlide.index ===
          this.$store.state.evaluadores.evaluacionSlide.evaluaciones.length - 1
        ) {
          index = 0;
        } else {
          index = this.$store.state.evaluadores.evaluacionSlide.index + 1;
        }
        this.goToRelatoDetail(
          this.$store.state.evaluadores.evaluacionSlide.evaluaciones[index],
          index
        );
      },
      goToRelatoDetail(evaluacion, index) {
        let limit = this.$store.state.evaluadores.evaluacionSlide.limit;

        if(limit !== undefined) {
          this.$store.commit("setEvaluacionSlide", {
            index,
            evaluaciones: this.evaluaciones,
            limit,
          });
        } else {
          this.$store.commit("setEvaluacionSlide", {
            index,
            evaluaciones: this.$store.state.evaluadores.evaluacionSlide
              .evaluaciones,
          });
        }

        this.$router.push({
          name: "relato",
          params: {
            relatoId: evaluacion.relato_id,
            usuarioId: this.$route.params.usuarioId,
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../styles/variables.scss";

  .container-data {
    margin-top: -220px;
    padding-bottom: 100px;
  }

  .container.data {
    background-color: white;
    padding-bottom: 35px;
  }

  .icon-mark {
    background-color: white;
    padding: 23px;
    width: 80px;
    height: 80px;
    display: inline-grid;
    margin: 5px;
    border-radius: 50%;
    font-size: 35px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .icon-mark.liked {
    color: $green;
  }

  .icon-mark.unliked {
    color: $primary;
  }

  .icon-mark.liked:hover {
    color: white;
    background-color: $green;

    .like-btn {
      color: white;
    }
  }

  .icon-mark.unliked:hover {
    color: white;
    background-color: $primary;

    .un-like-btn {
      color: white;
    }
  }

  .item-liked {
    color: white !important;
    background-color: $green;
  }

  .item-un-liked {
    color: white !important;
    background-color: $primary;
  }

  .btn-go-to-list {
    padding: 20px;
    background-color: white;
    border-radius: 20px;

    .icono {
      color: $primary;
      margin-right: 15px;
    }
  }

  .separacion {
    width: 100%;
  }

  .background-gray {
    padding: 20px;
    border-radius: 10px;
    background-color: $background-box;
  }

  .btn-slide {
    cursor: pointer;
  }

  .download-relato {
    cursor: pointer;
  }

  .disable-like {
    cursor: not-allowed;
  }

  .tag {
  margin-right: 8px;
  }
</style>